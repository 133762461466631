<!--
 * @Author: SongYijie
 * @Date: 2020-05-11 14:53:44
 * @LastEditors: SongYijie
-->
<template>
  <div class="inbu-operate-video">
    <h2>个体户注册操作指南</h2>

    <p class="title">注册指南</p>
    <div class="video-wrap">
      <videoPoster
        :imgUrl="require('@assets/images/individualBusiness/poster-1.png')"
        :videoUrl="require('@assets/images/individualBusiness/video-1.mp4')"
        idName="operator-video-1"
      />
    </div>
    

    <p class="title">税务实名认证指南</p>
    <div class="video-wrap">
      <videoPoster
        :imgUrl="require('@assets/images/individualBusiness/poster-2.png')"
        :videoUrl="require('@assets/images/individualBusiness/video-2.mp4')"
        idName="operator-video-2"
      />
    </div>
  </div>
</template>

<script>
import videoPoster from "@components/video-poster"
export default {
  name: "operate-video",
  components: {
    videoPoster
  }
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.inbu-operate-video {
  padding: 0 12px 50px;
  h2 {
    color: black;
    text-align: center;
    margin-top: 3vw;
  }
  .title {
    color: black;
    opacity: 0.9;
    margin: 3vw 0 2vw;
    text-align: left;
  }
  .video-wrap {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
}

</style>