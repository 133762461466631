<!--
 * @Author: SongYijie
 * @Date: 2020-09-27 14:48:50
 * @LastEditors: SongYijie
-->
<template>
  <div class="video-poster">
    <div class="image-wrap" v-if="imgVisible">
      <img :src="this.imgUrl" alt="psoter">
      <img src="@assets/images/individualBusiness/play-btn.png" alt="paly" class="play" @click="handlePaly">
    </div>
    <video controls preload="auto" :id="idName" width="100%" height="auto">
      <source :src="this.videoUrl"  type="video/mp4">
      您的浏览器不支持 HTML5 video 标签。
    </video>
  </div>
</template>

<script>
export default {
  props: ["imgUrl", "videoUrl", "idName"],
  data() {
    return {
      imgVisible: true,
    }
  },
  methods: {
    handlePaly() {
      this.imgVisible = false
      const videoNode = document.getElementById(this.idName);
      videoNode.play()
    }
  }
}
</script>

<style lang="less" scope>
  .video-poster {
    width: 100%;
    height: 100%;
    position: relative;
    .image-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .play {
        width: 22vw;
        height: 22vw;
        z-index: 4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    video {
      width: 100%;
      display: block;
    }
  }
</style>

